import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Rank from "./rank";
import Filters from "./filters";
import axios from "axios";

const DivBody = styled.div`
  min-height: 89vh;
  width: 100%;
  @media (min-width: 820px) {
    display: flex;
  }
`;
const DivMain = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const wait = async (time) => {
  const promise = (x, time) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(x);
      }, time);
    });
  };
  return await promise(null, time);
};

const Body = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState();
  const [currentFilter, setCurrentFilter] = useState("");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setLoading(true);
    setFilters(["date"]);
    let url =
      "https://rn3xfhamppsetddkod6vwc24lu0lhcek.lambda-url.us-east-1.on.aws/royal/component-rank";
    if (currentFilter !== "") {
      url += `?filter=${currentFilter}`;
    }

    axios.get(url).then(async (response) => {
      setData(response["data"]);
    });

    wait(1000)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentFilter]);

  const selectFilter = (value) => {
    let newFilter = "";
    if (Boolean(value)) {
      newFilter = value.toISOString().split("T")[0];
    }
    setCurrentFilter(newFilter);
  };

  return (
    <DivBody>
      <DivMain>
        <Filters
          filters={filters}
          onSelectFilter={selectFilter}
          setFilters={setFilters}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        ></Filters>
        <Rank loading={loading} data={data} search={searchValue}></Rank>
      </DivMain>
    </DivBody>
  );
};

export default Body;

//
